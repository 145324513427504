import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './css/sources.css';
import './css/style.css';
import './css/media.css';
import './App.css';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';



let Header = React.lazy(() => import("./Component/Header"))
let Footer = React.lazy(() => import("./Component/Footer"))
let Home = React.lazy(() => import("./View/Homepage/Home"))
let Contactus = React.lazy(() => import("./View/ContactUs/ContactUs"))
let About = React.lazy(() => import("./View/About/About"))
let HowitWorks = React.lazy(() => import("./View/HowItWorks/HowitWorks"))
let PrivacyPolicy = React.lazy(() => import("./View/Policies/PrivacyPolicy"))
let TermsAndConditions = React.lazy(() => import("./View/Policies/TermsAndConditions"))
// let Register = React.lazy(() => import("./View/Authentication/Register"))








function App() {
  return (
    <div className="App">
      <BrowserRouter basename='/'>
        <Header />
        <Routes>
          <Route exact path='/' element={<React.Suspense fallback={<>...</>}><Home /></React.Suspense>} />
         
          <Route exact path='/contact-us' element={<React.Suspense fallback={<>...</>}><Contactus /></React.Suspense>} />
          <Route exact path='/about-us' element={<React.Suspense fallback={<>...</>}><About /></React.Suspense>} />

          <Route exact path='/how-it-works' element={<React.Suspense fallback={<>...</>}><HowitWorks /></React.Suspense>} />

          <Route exact path='/privacy' element={<React.Suspense fallback={<>...</>}><PrivacyPolicy /></React.Suspense>} />
          
          <Route exact path='/terms-condition' element={<React.Suspense fallback={<>...</>}><TermsAndConditions /></React.Suspense>} />
          {/* <Route exact path='/registration' element={<React.Suspense fallback={<>...</>}><Register /></React.Suspense>} /> */}


          <Route path='/*' element={<Navigate to="/" />} />
          {/* <Route path='/contact-us' element={< />}  /> */}

        </Routes>
        <Footer />
      </BrowserRouter>
      <ToastContainer />
      
    </div>
  );
}

export default App;
